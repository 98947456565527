<template>
  <div v-swiper:Gallery="gallery">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="text-pink-500 gallery-pagination swiper-pagination"></div>
    <div class="gallery-prev absolute ml-2 left-0 top-2/4 z-10 outline-none w-auto hidden lg:block">
      <svg class="text-pink-500 w-8 h-8 outline-none transition-colors" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
      </svg>
    </div>
    <div class="gallery-next absolute mr-2 right-0 top-2/4 z-10 outline-none w-auto hidden lg:block">
      <svg class="text-pink-500 w-8 h-8 transition-colors" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GallerySlider',
  data () {
    return {
      gallery: {
        // cssMode: true,
        preventClicks: false,
        navigation: {
         nextEl: '.gallery-next',
         prevEl: '.gallery-prev',
        },
        pagination: {
          // clickable: true,
          el: '.gallery-pagination'
        },
        slidesPerView: 1,
        spaceBetween: 0
      }
    }
  }
}
</script>
