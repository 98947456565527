<template>
  <div class="space-y-6">
    <p class="font-bold text-2xl md:text-4xl lg:text-5xl">
      Contact Us
    </p>
    <div class="text-xl">
      Please call a member of our team on +44 (0)28 3852 5025 or complete the form below
    </div>
    <div>
      <validation-observer ref="form" v-slot="{ passes, invalid }">
        <div v-if="success" class="mb-8 bg-gray-100 border-t-4 border-gray-500 rounded-b text-gray-900 px-4 py-3 shadow" role="alert">
          <div class="flex">
            <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
            <div>
              <p class="font-bold">Thank You!</p>
              <p class="text-sm">{{ success }}</p>
            </div>
          </div>
        </div>
        <div v-if="error" class="mb-8 bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 shadow" role="alert">
          <div class="flex">
            <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
            <div>
              <p class="font-bold">Oops!</p>
              <p class="text-sm">{{ error }}</p>
            </div>
          </div>
        </div>
        <form class="grid gap-x-4 gap-y-2 md:grid-cols-4" @submit.stop>
          <validation-provider v-slot="{ errors }" name="Name" rules="required" class="md:col-span-2">
            <div>
              <label for="form-name" class="label required">Name</label>
              <input
                id="form-name"
                v-model="form.name"
                :class="{ error: errors[0] }"
                type="text"
                class="form-input input white"
                placeholder="Name"
              />
              <span v-show="errors" class="block mt-1 text-sm text-black">{{ errors[0] }}</span>
            </div>
          </validation-provider>
          <validation-provider v-slot="{ errors }" name="Email" rules="required|email" class="md:col-span-2">
            <div>
              <label for="form-email" class="label required">Email address</label>
              <input
                id="form-email"
                v-model="form.email"
                :class="{ error: errors[0] }"
                type="text"
                class="form-input input white"
                placeholder="Email address"
              />
              <span v-show="errors" class="block mt-1 text-sm text-black">{{ errors[0] }}</span>
            </div>
          </validation-provider>
          <validation-provider v-slot="{ errors }" name="Company" class="md:col-span-4">
            <div>
              <label for="form-company" class="label required">Company</label>
              <input
                id="form-company"
                v-model="form.company"
                :class="{ error: errors[0] }"
                type="text"
                class="form-input input white"
                placeholder="Company Name"
              />
              <span v-show="errors" class="block mt-1 text-sm text-black">{{ errors[0] }}</span>
            </div>
          </validation-provider>
          <validation-provider v-slot="{ errors }" name="Message" rules="required" class="md:col-span-4">
            <div>
              <label for="form-message" class="label required">Your Message</label>
              <textarea
                id="form-message"
                v-model="form.message"
                :class="{ error: errors[0] }"
                class="form-textarea textarea white"
                rows="4"
                placeholder="Write something..."
              ></textarea>
              <span v-show="errors" class="block mt-1 text-sm text-black">{{ errors[0] }}</span>
            </div>
          </validation-provider>
          <div class="md:col-span-2">
            <button type="button" :disabled="invalid" class="button solid white" @click="passes(SendMessage)">
              Send Message
            </button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  name: 'QuizContactForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    answers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      success: false,
      error: false,
      form: {}
    }
  },
  computed: {
    getPr () {
        return this.answers;
    }
  },
  methods: {
    Reset () {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.loading = true
      this.form.answers = this.answers
      this.$axios.post('/wp-json/icon/v1/contact', this.form)
        .then((response) => {
          this.form = {} // Clear input fields.
          this.$nextTick(() => this.$refs.form.reset())
          this.success = response.data.message
          this.error = false
          /*
          this.$notify({
            type: 'primary',
            showMethod: 'fade in right',
            message: response.data.message
          })*/
        })
        .catch((err) => {
          this.success = false
          this.error = err.response.data.message
          /*
          this.$notify({
            message: err.response.data.message
          })
          */
        })
        .then(() => {
          this.loading = false
        })
    }
  }
}
</script>
