<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="940px" height="280px" viewBox="0 0 940 280">
    <linearGradient id="SVGRED" gradientUnits="userSpaceOnUse" x1="930.3" y1="125.6061" x2="76.3384" y2="396.1281">
    	<stop offset="0" style="stop-color:#EE0100"/>
    	<stop offset="1" style="stop-color:#FFB900"/>
    </linearGradient>
    <linearGradient id="SVGTEAL" gradientUnits="userSpaceOnUse" x1="930.3" y1="125.6061" x2="76.3384" y2="396.1281">
    	<stop offset="0" style="stop-color:#00D6C8"/>
    	<stop offset="1" style="stop-color:#007195"/>
    </linearGradient>
    <linearGradient id="SVGPURPLE" gradientUnits="userSpaceOnUse" x1="930.3" y1="125.6061" x2="76.3384" y2="396.1281">
    	<stop offset="0" style="stop-color:#D80073"/>
    	<stop offset="1" style="stop-color:#731F95"/>
    </linearGradient>
    <linearGradient id="SVGPINK" gradientUnits="userSpaceOnUse" x1="930.3" y1="125.6061" x2="76.3384" y2="396.1281">
      <stop offset="0" style="stop-color:#FF0B4D"/>
      <stop offset="1" style="stop-color:#FF4895"/>
    </linearGradient>
    <linearGradient id="SVGGREEN" gradientUnits="userSpaceOnUse" x1="930.3" y1="125.6061" x2="76.3384" y2="396.1281">
    	<stop offset="0" style="stop-color:#00843D"/>
    	<stop offset="1" style="stop-color:#84BD00"/>
    </linearGradient>
    <path :fill="gradient" d="M940,28.8c-138.4-30.3-261.7,17-353.6,51.8C450.8,132,453.8,164.8,329.2,204.7 c-165.6,53.1-205.2,6.2-284.1,53.8C34.6,264.8,24.8,272,15.5,280H940V28.8z"/>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'red'
    }
  },
  computed: {
    gradient () {
      if (this.color === 'green') {
        return 'url(#SVGGREEN)'
      }else if (this.color === 'purple') {
        return 'url(#SVGPURPLE)'
      }else if (this.color === 'pink') {
        return 'url(#SVGPINK)'
      }else if (this.color === 'teal') {
        return 'url(#SVGTEAL)'
      }else {
        return 'url(#SVGRED)'
      }
    }
  }
}
</script>
