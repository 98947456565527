<template>
  <div>
    <div v-if="active_question" class="text-center space-y-6">
      <p class="font-bold text-2xl md:text-4xl lg:text-5xl">
        {{ active_question.text }}
      </p>
      <div v-if="active_question.description" class="text-xl">
        {{ active_question.description }}
      </div>
      <div class="flex justify-center space-x-4">
        <button v-for="(answer, index) in active_question.answers" :key="index" class="button solid" :class="answer.color" type="button" @click="setActive(answer)">
          {{ answer.label }}
        </button>
      </div>
    </div>
    <quiz-contact-form v-if="form" :answers="answers"></quiz-contact-form>
  </div>
</template>
<script>
export default {
  data () {
    return {
      form: false,
      active_question: null,
      answers: [],
      questions: [
        {
          id: 1,
          text: 'Do you need help with Lab services?',
          description: 'Take our short quiz to determine if you need help with lab services.',
          answers: [
            {
              label: 'No',
              color: 'black',
              action: 2
            },
            {
              label: 'Yes',
              color: 'white',
              action: 3
            }
          ]
        },
        {
          id: 2,
          text: 'Thank you for taking our quiz'
        },
        {
          id: 3,
          text: 'Asbestos Lab?',
          answers: [
            {
              label: 'No',
              color: 'black',
              action: 5
            },
            {
              label: 'Yes',
              color: 'white',
              action: 4
            }
          ]
        },
        {
          id: 4,
          text: 'Do you require samples tested?',
          answers: [
            {
              label: 'No',
              color: 'black',
              action: 1
            },
            {
              label: 'Yes',
              color: 'white',
              action: 'form'
            }
          ]
        },
        {
          id: 5,
          text: 'Water Lab?',
          answers: [
            {
              label: 'No',
              color: 'black',
              action: 3
            },
            {
              label: 'Yes',
              color: 'white',
              action: 6
            }
          ]
        },
        {
          id: 6,
          text: 'Domestic?',
          answers: [
            {
              label: 'No',
              color: 'black',
              action: 7
            },
            {
              label: 'Yes',
              color: 'white',
              action: 'form'
            }
          ]
        },
        {
          id: 7,
          text: 'Commercial?',
          answers: [
            {
              label: 'No',
              color: 'black',
              action: 1
            },
            {
              label: 'Yes',
              color: 'white',
              action: 'form'
            }
          ]
        },
      ]
    }
  },
  mounted () {
    this.active_question = this.questions[0]
  },
  methods: {
    setActive(answer) {
      this.answers.push(
        {
          text: this.active_question.text,
          value: answer.label
        }
      )
      if(answer.action === 'form') {
        this.active_question = null
        this.form = true
      }else{
        this.active_question = this.questions.find(question => question.id === answer.action)
      }
    }
  },
}
</script>
