<template>
  <div class="fixed-menu sticky z-30 transition-colors duration-200">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'AppFixedHeader',
  data () {
    return {
      observer: null
    }
  },
  created () {
    this.observer = new IntersectionObserver(
      this.onElementObserved,
      { root: this.$el, threshold: [1] }
    )
  },
  mounted () {
    this.observer.observe(this.$el)
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
  methods: {
    onElementObserved ([e]) {
      e.target.classList.toggle('has-bg', e.intersectionRatio < 1)
    }
  }
}
</script>
<style lang="scss">
.has-bg{
  background: #fff;
  color: #212121;
  box-shadow: inset 0 -1px 0 0 #fff, 0 1px 5px rgba(0,0,0,.1);

  .brand{
    display: block;
    margin-top: -30px;
    @media (min-width: 768px) {
      margin-top: -60px;
    }
  }
}
.brand{
  margin-top: 0;
  display: block;
  transition: margin 0.50s;
}
body.home .fixed-menu:not(.has-bg),
body.white-menu .fixed-menu:not(.has-bg) {
  color: white;
}

body.home .fixed-menu,
body.white-menu .fixed-menu {
  margin-bottom: -96px;
  @media (min-width: 768px) {
    margin-bottom: -128px;
  }
}

.fixed-menu{
  top:-1px;
}
</style>
