<template>
  <div class="relative pointer-events-none transform rotate-6 lg:rotate-12">
    <div class="relative flex h-32" :class="{ '-mt-32' : cta }">
      <div class="absolute wave" :class="color"></div>
      <div class="absolute wave" :class="color"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'red'
    },
    cta: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.wave{
  height:198px;
  width: 6400px;
  top:0;
  animation: wave 10s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  background-repeat: repeat-x;
  background-position:center;
  transform: translate3d(0, 0, 0);
  &.red{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' fill='none' stroke='%23ffbb00' stroke-width='2' height='100%25' viewBox='0 0 1600 200'%3E%3Cg %3E%3Cpath d='M1600,152c-311,0-409.9-114.2-811-114C389,38,289,152,0,152'/%3E%3C/g%3E%3C/svg%3E");
  }
  &.purple{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' fill='none' stroke='%23D80073' stroke-width='2' height='100%25' viewBox='0 0 1600 200'%3E%3Cg %3E%3Cpath d='M1600,152c-311,0-409.9-114.2-811-114C389,38,289,152,0,152'/%3E%3C/g%3E%3C/svg%3E");
  }
  &.pink{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' fill='none' stroke='%23ff2457' stroke-width='2' height='100%25' viewBox='0 0 1600 200'%3E%3Cg %3E%3Cpath d='M1600,152c-311,0-409.9-114.2-811-114C389,38,289,152,0,152'/%3E%3C/g%3E%3C/svg%3E");
  }
  &.teal{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' fill='none' stroke='%2300D6C8' stroke-width='2' height='100%25' viewBox='0 0 1600 200'%3E%3Cg %3E%3Cpath d='M1600,152c-311,0-409.9-114.2-811-114C389,38,289,152,0,152'/%3E%3C/g%3E%3C/svg%3E");
  }
  &.green{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' fill='none' stroke='%2384bd00' stroke-width='2' height='100%25' viewBox='0 0 1600 200'%3E%3Cg %3E%3Cpath d='M1600,152c-311,0-409.9-114.2-811-114C389,38,289,152,0,152'/%3E%3C/g%3E%3C/svg%3E");
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

.wave:nth-of-type(2) {
  top: 10px;
  animation: wave 3s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
}


@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,25px,0);
  }
}
</style>
