<template>
  <div id="nav">
    <div class="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav class="w-full relative flex items-center justify-between">
        <div class="relative p-4 text-center">
          <slot name="brand" />
        </div>
        <div class="hidden md:flex items-center justify-between md:w-full">
          <div></div>
          <div class="flex items-center ml-auto">
            <div class="navigation relative flex justify-center mr-8" @mouseover="services = true" @mouseleave="services = false" @focusin="services = true">
              <span class="menu-item">
                <a href="/services/" class="inline-flex items-center">
                  Services
                  <svg class="ml-2 h-5 w-5 opacity-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </a>
              </span>
              <!--
                'Solutions' flyout menu, show/hide based on flyout menu state.

                Entering: "transition ease-out duration-200"
                  From: "opacity-0 translate-y-1"
                  To: "opacity-100 translate-y-0"
                Leaving: "transition ease-in duration-150"
                  From: "opacity-100 translate-y-0"
                  To: "opacity-0 translate-y-1"
              -->
              <div v-if="services" class="absolute z-10 mt-6 pt-4 transform px-2 w-screen max-w-690">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid grid-cols-2 gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <slot name="services" />
                  </div>
                </div>
              </div>
            </div>
            <slot />
          </div>
        </div>
        <!-- <div class="w-24 flex justify-end items-center px-4">
          <slot name="icons-right" />
        </div> -->
        <div class="flex-shrink-0 px-4 md:hidden">
          <button ref="openButton" type="button" class="block focus:outline-none" aria-label="Menu" @click="openLeft">
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" />
              <path d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z" />
              <path d="M4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H4Z" />
            </svg>
          </button>
        </div>
      </nav>
    </div>
    <div class="md:hidden">
      <!-- Off-canvas menu background overlay -->
      <transition
        enter-class="opacity-0"
        enter-active-class="ease-out transition-medium"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-active-class="ease-out transition-medium"
        leave-to-class="opacity-0"
        appear
      >
        <div v-show="isOpen || isOpenLeft" class="z-20 fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-black opacity-50" tabindex="-1" @click="close" />
        </div>
      </transition>
      <!-- Off-canvas menu -->
      <transition
        enter-class="translate-y-full"
        enter-active-class="ease-out transition-slow"
        enter-to-class="translate-y-0"
        leave-class="translate-y-0"
        leave-active-class="ease-in transition-medium"
        leave-to-class="translate-y-full"
        appear
      >
        <div v-show="isOpenLeft" class="z-20 fixed inset-0 transition-transform">
          <div class="relative bg-white flex flex-col h-full z-20 text-gray-900 overflow-y-auto">
            <div class="text-right px-4 sm:px-6 lg:px-8 py-4">
              <button ref="closeButton" type="button" class="px-4 focus:outline-none" aria-label="Close" @click="close">
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L13.4142 12L19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L12 10.5858L5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L10.5858 12L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L12 13.4142L18.2929 19.7071Z" />
                </svg>
              </button>
            </div>
            <div class="flex h-full justify-center items-center font-header text-center pb-8">
              <slot name="mobile-menu" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
    }
  },
  methods: {
    open () {
      this.isOpen = true
    },
    openLeft () {
      this.isOpenLeft = true
    },
    close () {
      this.isOpen = false
      this.isOpenLeft = false
    }
  }
}
</script>
<style lang="scss">
.ease-linear {
  transition-timing-function: linear
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.transition-fastest {
  transition-duration: 75ms
}

.transition-faster {
  transition-duration: 100ms
}

.transition-fast {
  transition-duration: 150ms
}

.transition-medium {
  transition-duration: 200ms
}

.transition-slow {
  transition-duration: 300ms
}

.transition-slower {
  transition-duration: 500ms
}

.transition-slowest {
  transition-duration: 700ms
}

.transition-all {
  transition-property: all
}

.transition-opacity {
  transition-property: opacity
}

.transition-transform {
  transition-property: transform
}

.translate-y-full {
  transform: translateY(100%);
}
.-translate-y-full {
  transform: translateY(-100%);
}
.translate-y-0 {
  transform: translateY(0);
}

.scale-90 {
  transform: scale(.9)
}

.scale-100 {
  transform: scale(1)
}

.origin-top {
  transform-origin: top
}

.origin-top-right {
  transform-origin: top right
}

.origin-right {
  transform-origin: right
}

.origin-bottom-right {
  transform-origin: bottom right
}

.origin-bottom {
  transform-origin: bottom
}

.origin-bottom-left {
  transform-origin: bottom left
}

.origin-left {
  transform-origin: left
}

.origin-top-left {
  transform-origin: top left
}
</style>
