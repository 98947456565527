window.Vue = require('vue')
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import axios from 'axios'

import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import ContactForm from './components/ContactForm'
import AppFixedHeader from './components/AppFixedHeader'
import TestimonialSlider from './components/TestimonialSlider'
import AppNavBar from './components/AppNavBar'
import Accordion from './components/Accordion'
import GallerySlider from './components/GallerySlider'
import QuizAsbestos from './components/QuizAsbestos'
import QuizLab from './components/QuizLab'
import QuizAir from './components/QuizAir'
import QuizLegionella from './components/QuizLegionella'
import QuizContactForm from './components/QuizContactForm'
Vue.component('ContactForm', ContactForm)
Vue.component('TestimonialSlider', TestimonialSlider)
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)
Vue.component('Accordion', Accordion)
Vue.component('GallerySlider', GallerySlider)
Vue.component('QuizAsbestos', QuizAsbestos)
Vue.component('QuizAir', QuizAir)
Vue.component('QuizLab', QuizLab)
Vue.component('QuizLegionella', QuizLegionella)
Vue.component('QuizContactForm', QuizContactForm)

const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

const mess = require.context('./components/mess/', true, /\.vue$/i)
mess.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], mess(key).default))


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})

Vue.prototype.$axios = axios
Vue.config.productionTip = false
